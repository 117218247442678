import { range } from 'lodash';
import React, { forwardRef } from 'react'
import Select, { Theme } from 'react-select';
// import { selectTheme } from '@/features/search/styles';

export const selectTheme = (theme: Theme) => ({
    ...theme,
    // borderRadius: 0,
    colors: {
        ...theme.colors,
        neutral80: 'var(--colors-text)',
        neutral20: 'var(--colors-outline)', // '$hiContrast',
        primary25: 'var(--colors-primary3)',
        // neutral0: 'var(--colors-monthbg)',
        neutral10: 'var(--colors-secondary7)',
        // neutral0: 'yellow',
        // neutral5: 'yellow',
        // neutral10: 'yellow',
        // neutral30: 'var(--colors-focus)',
        // neutral40: 'yellow',
        // neutral50: 'yellow',
        primary: 'var(--colors-gray11)'
    },
})

export const minutesToLabel = (value: number) => {
    const hours = Math.floor(value / 60)
    const min = value % 60
    let label = ''
    if (hours) label += `${hours}h`
    if (hours && min) label += ' '
    if (min) label += ` ${min}m`
    return label
}

// value is durationInMinutes
const DurationSelect = forwardRef<any, { durationInMinutes: number, onChange: (d: number) => void }>(({
    durationInMinutes, onChange, ...props }, ref) => {
    const options = range(15, 600, 15).map(minutes => {
        return { value: minutes, label: minutesToLabel(minutes) }
    })

    return (
        <div style={{ width: 150 }}>
            <Select
                placeholder='Duration'
                options={options}
                value={{ value: durationInMinutes, label: minutesToLabel(durationInMinutes) }}
                name={'duration-select'}
                ref={ref}
                theme={selectTheme}
                onChange={(option) => onChange(option?.value || 60)}
                {...props}
            />
        </div>
    )
})

DurationSelect.displayName = 'DurationSelect'
export default DurationSelect