'use client'
import React, { useEffect, useState } from 'react'
import { styled } from '@/stitches.config'
import styles from './meet.module.css'
import { Flex, Input } from '@planda/design-system'
import { BsPlayFill } from 'react-icons/bs'
import { nanoid } from 'nanoid'
import DurationSelect from '@/components/DurationSelect'
import { fetcher } from '@/lib/fetch'
import { useRouter } from 'next/navigation'
import { MEETING_ID_SIZE } from '@/constants'

const Create = () => {
    const id = nanoid(MEETING_ID_SIZE)
    const [durationInMinutes, setDurationInMinutes] = useState(30)
    const router = useRouter()



    const createMeeting = () => {
        fetcher(`/api/${id}`, 'POST', {
            durationInMinutes
        })
        router.push(`/${id}/share`)
    }

    useEffect(() => {
        // Prefetch the share page
        router.prefetch(`/${id}/share`)
    }, [router])

    return (
        <Page>
            <h1 className={styles.title}>Planda Meet</h1>
            <Input placeholder="Enter a meeting name" />
            <Flex gap={7} style={{ position: 'relative' }}>
                <DurationSelect durationInMinutes={durationInMinutes} onChange={setDurationInMinutes} />
                <Play onClick={createMeeting} />
            </Flex>
        </Page>
    )
}

export default Create

const Page = styled('div', {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100vh',
    backgroundColor: '$gray100',
    gap: 20,
})

const Play = styled(BsPlayFill, {
    borderRadius: '50%',
    backgroundColor: '#90DEB8',
    color: '#062318',
    // height: '100%',
    // // width: '100%',
    // aspectRatio: '1/1',
    padding: 10,
    height: 60,
    width: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
        backgroundColor: '#62C08E',
        scale: 1.05
    }
})